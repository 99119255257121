/**
 * @author  Regimag.in
 * @description  This hook is used fetch fund data specific to the fund id
 *
 */

import useApiQuery, { ApiQueryProps } from "./useApiQuery";

type FundQueryType = (
  id: string,
  section?: string,
  options?: ApiQueryProps
) => any;
const useGoalsQuery: FundQueryType = (
  id: string,
  section?: string,
  options = {}
) => {
  const endpoint = section ? `/funds/${id}/${section}` : `/funds/${id}/goals`;
  return useApiQuery(endpoint, {
    queryKey: ["funds", id, section].filter((s) => s),
    enabled: !!id,
    ...options,
  });
};

export default useGoalsQuery;
