/**
 * @author  Regimag.in
 * @description  This hook is used fetch fund data specific to the fund id
 *
 */

import useApiQuery, { ApiQueryProps } from "./useApiQuery";



/**
 * 
https://43g9b4iew3.execute-api.us-east-1.amazonaws.com/funds/b9e69239-b806-43e5-a040-e3ff1aaf58e7/reports
https://43g9b4iew3.execute-api.us-east-1.amazonaws.com/funds/b9e69239-b806-43e5-a040-e3ff1aaf58e7/company-static-reports
 */

type ReportsQueryType = (
  id: string,
  isCompany?: boolean,
  options?: ApiQueryProps
) => any;
const useReportsQuery: ReportsQueryType = (
  id: string,
  isCompany: false,
  options = {}
) => {
  const endpoint = isCompany ? `/funds/${id}/company-static-reports` : `/funds/${id}/reports`;
  return useApiQuery(endpoint, {
    queryKey: ["reports", id, isCompany].filter((s) => s),
    enabled: !!id,
    ...options,
  });
};

export default useReportsQuery;
