import {PerformanceTable} from "@components/Goals/PerformanceTable";
import { Tabs, Typography } from "antd";
import { useEffect, useState } from "react";

const { Text } = Typography;

export const PortfolioPerformance = (props) => {

  const [tabs, setTabs] = useState(null);

  useEffect(() => {
    if (tabs === null) {
      const tables = createTables(props.data);
      const newTabs = [];
      
      Object.keys(tables).map((metric, index) => {
        newTabs.push({
    
          key: metric,
          label: <Text style={{color:'#2D324E', fontWeight:500}}>{metric}</Text>,
          children: <PerformanceTable companies={tables[metric]}/>
                    
        });    
      });

      setTabs(newTabs);
    }
  }, [props.goals]);

  return (
    <>
      <Text
        style={{fontSize: "16px", fontWeight: 700 }}
      >
        Portfolio Performance
      </Text>  
      
        
        <Tabs
          style={{padding:'0px',}}
          type="card"
          items={tabs}
          tabBarStyle={{color:'red'}}
        />
      
    </>
  );
}

function createTables(data) {

  const tables = {};

  Object.keys(data).map((companyID, index) => {
    
    console.log("createPerformance", data[companyID]);

    createMetrics(data[companyID].metrics,
                  tables, 
                  data[companyID].company.companyDisplayName, 
                  data[companyID].company.investmentYear, 
                  data[companyID].company.companyProfileUrl, 
                  companyID);
  });

  return tables;
}

function createMetrics(metrics, tables, name, investmentYear, icon, companyID) {

    metrics.map((metric, index) => {
      
      console.log("createMetric", metric.metricName);
      var table = tables[metric.metricName];

      if (!table) {
        table = [];
        tables[metric.metricName] = table;
      }

      const performance = {
        key: companyID,
        name: name,
        investmentYear: investmentYear,
        icon: icon
      }
      
      processYears(performance, metric.years);

      //const status = calcStatus(metrics[metric]);

      table.push(performance);
    });
}
// assumptions:
// 1. metrics can be parsed as strings
// 2. each company has same years up to most recent
// 3. only operators are < > =
// 4. years arrive oldest first
// 5. quarters are consistant in order (ie q4 if 4th)

function processYears(performance, years) {

  const yearsLatestFirst = Object.keys(years).reverse();
  var isFirst = true;

  const yearData = {};
  const olderYears = {};
  yearsLatestFirst.map((year, index) => {
   
    console.log("processijg year", year);
    if (isFirst) {
      const recent = {};
      recent['mostRecentYear'] = year;
      recent['q1'] = years[year][0].value;
      recent['q2'] = years[year][1].value;
      recent['q3'] = years[year][2].value;
      recent['q4'] = years[year][3].value;
      yearData['recent'] = recent;
      isFirst = false;
    }
    else {
      olderYears[year] = parseQuarters(years[year]);
    }
    yearData['olderYears'] = olderYears;
    performance.years = yearData;
  });  
}

function parseQuarters(year) {
  var valuesFound = 0;
  var totalValue = 0;

  if (year[0].value !== undefined) {
    totalValue = parseInt(year[0].value);
    valuesFound++;
  }

  if (year[1].value !== undefined) {
    totalValue = totalValue + parseInt(year[1].value);
    valuesFound++;
  }

  if (year[2].value !== undefined) {
    totalValue = totalValue + parseInt(year[2].value);
    valuesFound++;
  }

  if (year[3].value !== undefined) {
    totalValue = totalValue + parseInt(year[3].value);
    valuesFound++;
  }

  return totalValue / valuesFound;
}


function calcStatus(metric) {

  const status = 0;
  const value = 0;
  const companyTarget = parseInt(metric.companyTarget);
  const fundTarget = parseInt(metric.fundTarget);

  if (metric.companyTargetOperator === ">" && companyTarget >value ) {

  }
}