import {
  Col,
  Button,
  Row,
  Layout,
  Space,
  Typography,
  Dropdown,
  ConfigProvider,
  Image,
  Divider,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ReactComponent as helpIcon } from "@icons/help.svg";
import { ReactComponent as fundIcon } from "@logos/BCSF.svg";

import Icon from "@ant-design/icons";
import { signOut } from "./ContextProviders/AuthContext";
import useAuth from "@hooks/useAuth";
import useMeQuery from "@hooks/api/useMeQuery";

import { CSSProperties } from "react";
import { StyleProvider } from "@ant-design/cssinjs";

const { Text } = Typography;
const { Header } = Layout;

function ReimaginHeader() {
  const { user } = useAuth();
  const { data, isLoading } = useMeQuery();

  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: "Sign Out",
    },
  ];

  const headerStyle: CSSProperties = {
    textAlign: "center",
    color: "#374151",
    height: 64,
    marginBottom: 2,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffffff",
    fontWeight: 500,
    fontSize: "46px",
    fontFamily: "Inter",
  };

  const goToFundDetails = () => {
    navigate("/funddetails");
  };

  const onClick = ({ key }) => {
    if (key === "1") {
      signOut(user, navigate);
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Inter",
          },
          components: {
            Button: {
              colorPrimary: "#E7F9FA",
            },
          },
        }}
      >
        <StyleProvider hashPriority="high">
          <Header style={headerStyle}>
            <Row justify="start" className="flex items-center">
              <Col>
                <Button
                  className="flex items-center"
                  type="primary"
                  shape="round"
                  onClick={goToFundDetails}
                >
                  <Icon component={fundIcon} />
                  <Text style={{ fontWeight: 600, color: "#374151" }}>
                    Bain Capital
                  </Text>
                </Button>
              </Col>
              <Col flex="auto"></Col>
              <Col>
                <Space>
                  <Button
                    className="flex items-center"
                    type="primary"
                    shape="round"
                    size="small"
                  >
                    <Icon component={helpIcon} />
                    <Text style={{ fontWeight: 500, color: "#374151" }}>
                      Help
                    </Text>
                  </Button>
                  <Divider
                    className="h-6"
                    style={{ backgroundColor: "#76798B" }}
                    type="vertical"
                  />
                  <Dropdown menu={{ items, onClick }}>
                    <a href="/" onClick={(e) => e.preventDefault()}>
                      <div className="flex items-center">
                        {isLoading ? (
                          <></>
                        ) : (
                          <>
                            <div
                              style={{
                                color: "#374151",
                                fontWeight: 500,
                                marginLeft: "12px",
                                marginRight: "12px",
                              }}
                            >
                              {user}
                            </div>
                            <Image
                              preview={false}
                              width="32px"
                              src={data?.profileUrl}
                            />
                            <DownOutlined
                              style={{
                                marginLeft: "8px",
                                color: "#76798B",
                                fontSize: "50%",
                              }}
                            />
                          </>
                        )}
                      </div>
                    </a>
                  </Dropdown>
                </Space>
              </Col>
            </Row>
          </Header>
        </StyleProvider>
      </ConfigProvider>
    </>
  );
}

export default ReimaginHeader;
