import { Card, Col, ConfigProvider, Row, Skeleton, Alert } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Page from "@screens/Page";
import {
  PortfolioTable,
  PortfolioTableRowDataType,
} from "@components/PortfolioTable";
import MetricPanel from "@components/Dashboards/MetricPanel";
import FilterDropdown from "@components/FilterDropdown";
import useFundQuery from "@hooks/api/useFundQuery";
import useActiveFund from "@hooks/useActiveFund";
import useStaticConfig from "@hooks/useStaticConfig";
import { ReactComponent as percentageIcon } from "@icons/percentage.svg";
import { ReactComponent as co2LowIcon } from "@icons/co2-lo.svg";
import { ReactComponent as co2HighIcon } from "@icons/co2-hi.svg";
import { ReactComponent as genderlessRedIcon } from "@icons/genderless-red.svg";
import { ReactComponent as genderlessBlueIcon } from "@icons/genderless-blue.svg";
import { ReactComponent as buildingLineIcon1 } from "@icons/building-line-blue1.svg";
import { ReactComponent as buildingLineIcon2 } from "@icons/building-line-blue2.svg";
import { getYears } from "src/utils";

const YEARS = getYears({ direction: "past", duration: 10 });

type FilterBarProps = {
  values?: { investmentYears?: string[]; location?: string[] };
  onFilterLocation: (value: string[]) => void;
  onFilterInvestmentYears: (value: string[]) => void;
};
const FilterBar = ({
  values: { location, investmentYears },
  onFilterLocation,
  onFilterInvestmentYears,
}: FilterBarProps) => {
  const { data, isLoading } = useStaticConfig();
  const [industryData, setIndustryData] = useState<
    { value: string; label: string }[]
  >([]);
  const [locationData, setLocationData] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    if (data?.staticIndustrySectors && industryData.length === 0) {
      const newData = data.staticIndustrySectors.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setIndustryData(newData);
    }
  }, [data, industryData]);

  useEffect(() => {
    if (data?.staticLocationOfInvestments && locationData.length === 0) {
      const newData = data.staticLocationOfInvestments.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setLocationData(newData);
    }
  }, [data, locationData]);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Card: {
              colorBgContainer: "#F9FAFB",
              colorBorder: "#E5E7EB",
            },
          },
          token: {
            fontSize: 12,
            borderRadius: 12,
          },
        }}
      >
        <Card
          headStyle={{ border: 0 }}
          style={{ textAlign: "left" }}
          title="Filter Portfolio By:"
          bodyStyle={{ paddingTop: "10px" }}
        >
          <Row>
            <Col
              lg={{ span: 6 }}
              sm={{ span: 12 }}
              style={{ textAlign: "center", padding: "0 4px 0 0" }}
            >
              <FilterDropdown
                isLoading={isLoading}
                onChange={onFilterInvestmentYears}
                label="Investment Year"
                options={YEARS}
                values={investmentYears}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 6 }}
              sm={{ span: 12 }}
              style={{ textAlign: "center", padding: "0 4px 0 0" }}
            >
              <FilterDropdown
                isLoading={isLoading}
                label="Location"
                options={locationData}
                onChange={onFilterLocation}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 6 }}
              sm={{ span: 12 }}
              style={{ textAlign: "center", padding: "0 4px 0 0" }}
            >
              <FilterDropdown
                isLoading={isLoading}
                label="Industry"
                options={industryData}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 6 }}
              sm={{ span: 12 }}
              style={{ textAlign: "center", padding: "0" }}
            >
              <FilterDropdown
                isLoading={isLoading}
                label="Investment Status"
                options={[]}
              />
            </Col>
          </Row>
        </Card>
      </ConfigProvider>
    </>
  );
};

const labels = {
  highestAttrition: "Highest Attrition",
  highestEmissions: "Highest Emissions",
  lessDiverseWorkForce: "Least Diverse Workforce",
  lowestAttrition: "Lowest Attrition",
  lowestEmission: "Lowest Emissions",
  mostDiverseWorkforce: "Most Diverse Workforce",
  percentagePayingLivingWage: "Percentage Paying Living Wage",
};

const MetricIcons = {
  highestAttrition: buildingLineIcon2,
  highestEmissions: co2HighIcon,
  lessDiverseWorkForce: genderlessRedIcon,
  lowestAttrition: buildingLineIcon1,
  lowestEmission: co2LowIcon,
  mostDiverseWorkforce: genderlessBlueIcon,
  percentagePayingLivingWage: percentageIcon,
};

export const portfolioRowTransform = (item: any, key: number) => {
  return {
    key,
    id: item.id,
    company: item.displayName,
    icon: item.profileUrl,
    year: new Date(item.dateOfIncorporation).getFullYear(),
    industry: item.industry,
    headcount: item.totalEmployees,
    investmentDate: item.investmentDate,
    amtFunded: item.totalInvested,
    revenue: item.totalRevenue,
    ebitda: item.totalEbitda,
  };
};

export const portfolioDataTransform = (data: any) => {
  const table = data.data.map(portfolioRowTransform);
  return { ...data, table };
};

export const Portfolio = () => {
  const [items, setIems] = useState<any[]>([]);
  const { activeFundId } = useActiveFund();
  const [tableData, setTableData] = useState<PortfolioTableRowDataType[]>([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const years = searchParams.get("y")?.split(",") || [];

  const [investmentYears, setInvestmentYears] = useState<string[]>(years);
  const enabled =
    !!activeFundId && investmentYears && investmentYears.length > 0;
  const { data, isLoading }: any = useFundQuery(activeFundId, "portfolios", {
    queryParams: { investmentYears: investmentYears.join(",") },
    queryKey: ["portfolios", activeFundId, investmentYears.join(",")],
    transform: portfolioDataTransform,
    enabled,
  });

  useEffect(() => {
    if (data && enabled) {
      const table = data.data.map(portfolioRowTransform);
      setTableData(table);
      const shaped = Object.keys(data.metadata).reduce((acc, key) => {
        return [
          ...acc,
          {
            label: labels[key],
            value: data.metadata[key]?.name || data.metadata[key],
            icon: MetricIcons[key],
          },
        ];
      }, []);

      setIems(shaped);
    } else {
      setIems([]);
      setTableData([]);
    }
  }, [data, enabled]);

  return (
    <Page siderIndex="1">
      <ConfigProvider
        theme={{
          token: {
            fontSize: 12,
          },
        }}
      >
        {!investmentYears ||
          (investmentYears.length === 0 && (
            <Alert
              style={{ marginBottom: "12px" }}
              message="Select an investment year to view portfolio companies"
              type="info"
              showIcon
            />
          ))}
        <FilterBar
          values={{ investmentYears }}
          onFilterInvestmentYears={(v) => {
            setInvestmentYears(v);
            setSearchParams({ y: v.join(",") });
          }}
          onFilterLocation={(value) => console.log(value)}
        />

        {isLoading && enabled ? (
          <Skeleton
            style={{ marginTop: "12px" }}
            avatar
            active
            paragraph={{ rows: 4 }}
          />
        ) : (
          <MetricPanel items={items} />
        )}
        <PortfolioTable dataSource={tableData} />
      </ConfigProvider>
    </Page>
  );
};

export default Portfolio;
