import { InsightsTable, RowType as TableRow } from "@components/InsightsTable";
import FilterDropdown from "@components/FilterDropdown";
import { Card, Col, ConfigProvider, Row, Select, Skeleton, Alert } from "antd";
import useActiveFund from "@hooks/useActiveFund";
import useFundQuery from "@hooks/api/useFundQuery";
import { useEffect, useState } from "react";
import useDashboardQuery from "@hooks/api/useDashboardQuery";
import log from "loglevel";
import { YEARS } from "@constants/years";

type FilterBarProps = {
  onFilterCompanies: (value: string[]) => void;
  onFilterFramework: (value: string) => void;
  onFilterFrom: (value: string) => void;
  onFilterTo: (value: string) => void;
};

const FilterBar = ({
  onFilterCompanies,
  onFilterFramework,
  onFilterFrom,
  onFilterTo,
}: FilterBarProps) => {
  const { activeFundId } = useActiveFund();
  const { data: portfolioData, isLoading: isLoadingPortfolio } =
    useDashboardQuery(activeFundId);
  const { data: fundData, isLoadingFund } = useFundQuery(activeFundId);

  log.debug("Portfolio2:", portfolioData);

  const [sanitizedPortfolioData, setSanitizedPortfolioData] = useState<
    { value: string; label: string }[]
  >([]);

  const [frameworkData, setFrameworkData] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    if (portfolioData?.portfolios && sanitizedPortfolioData.length === 0) {
      const newData = portfolioData.portfolios.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSanitizedPortfolioData(newData);
      console.log("DATA", newData);
    }
  }, [sanitizedPortfolioData, portfolioData]);

  useEffect(() => {
    if (fundData?.frameworks && frameworkData.length === 0) {
      const newData = fundData.frameworks.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setFrameworkData(newData);
    }
  }, [fundData, frameworkData]);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Card: {
              colorBgContainer: "#F9FAFB",
              colorBorder: "#E5E7EB",
            },
          },
          token: {
            fontSize: 12,
            borderRadius: 12,
          },
        }}
      >
        <Card
          headStyle={{ border: 0 }}
          style={{ textAlign: "left" }}
          title="Filter Insights By:"
          bodyStyle={{ paddingTop: "10px" }}
        >
          <Row>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 6 }}
              sm={{ span: 12 }}
              style={{ textAlign: "center", padding: "0 4px 0 0" }}
            >
              <FilterDropdown
                isLoading={isLoadingPortfolio}
                label="Company"
                options={sanitizedPortfolioData}
                onChange={onFilterCompanies}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 6 }}
              sm={{ span: 12 }}
              style={{ textAlign: "center", padding: "0 4px 0 0" }}
            >
              <Dropdown
                isLoading={isLoadingFund}
                label="Framework"
                options={frameworkData}
                onChange={onFilterFramework}
              />
            </Col>
            <Col
              lg={{ span: 4 }}
              sm={{ span: 8 }}
              style={{ textAlign: "center", padding: "0 4px 0 0" }}
            >
              <Dropdown
                isLoading={isLoadingFund}
                onChange={onFilterFrom}
                options={YEARS}
                label="From Investment Year"
              />
            </Col>
            <Col
              lg={{ span: 4 }}
              sm={{ span: 8 }}
              style={{ textAlign: "center", padding: "0 4px 0 0" }}
            >
              <Dropdown
                isLoading={isLoadingFund}
                onChange={onFilterTo}
                options={YEARS}
                label="To Investment Year"
              />
            </Col>
          </Row>
        </Card>
      </ConfigProvider>
    </>
  );
};

type DropdownProps = {
  label?: string;
  options: { value: string; label: string }[];
  onChange?: (value: string) => void;
  isLoading?: boolean;
};

export const Dropdown = ({
  label,
  options,
  onChange,
  isLoading = false,
}: DropdownProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
          borderRadius: 5,
          controlHeight: 45,
        },
      }}
    >
      <Select
        showSearch
        loading={isLoading}
        style={{
          width: "100%",
          textAlign: "left",
          margin: "10px 0",
          minWidth: "150px",
        }}
        dropdownStyle={{ width: "100%" }}
        placeholder={label}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={options}
        onChange={onChange}
      />
    </ConfigProvider>
  );
};

interface TableProps {
  rows: TableRow[];
  metric2description: {};
  rowScopes: number[];
  timePeriod: string;
  title: string;
  companyIds: {};
  companies: string[];
}

const Insights = () => {
  const [companies, setCompanies] = useState<string[]>([]);
  const [framework, setFramework] = useState<string>();
  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();
  const [tableData, setTableData] = useState<TableProps>();
  const { activeFundId } = useActiveFund();

  const enabled =
    !!activeFundId &&
    companies &&
    companies.length > 0 &&
    from !== undefined &&
    to !== undefined &&
    framework !== undefined;

  const { data, isLoading }: any = useFundQuery(activeFundId, "insights", {
    queryParams: {
      companyIds: companies.join(","),
      from: from,
      to: to,
      staticReportingFrameworkId: framework,
    },
    queryKey: [
      "insights",
      activeFundId,
      companies.join(",") + from + to + framework,
    ],
    //transform: portfolioDataTransform,
    enabled,
  });

  log.debug("From:", from);
  log.debug("To:", to);
  log.debug("Framework:", framework);
  log.debug("Companies:", companies.join(","));
  log.debug("Insights:", data);

  useEffect(() => {
    log.debug("isLoading?", isLoading);
    if (data && !isLoading) {
      const metric2description = {};
      const rowScopes = [];
      const newTableData: TableRow[] = [];

      createCategories(
        data.categories,
        newTableData,
        rowScopes,
        metric2description
      );

      const newData: TableProps = {
        rows: newTableData,
        metric2description,
        rowScopes,
        timePeriod: from + "-" + to,
        title: data.title,
        companyIds: data.companies,
        companies,
      };
      setTableData(newData);
    }
  }, [data, isLoading, to, from, companies]);

  return (
    <>
      <FilterBar
        onFilterCompanies={setCompanies}
        onFilterFramework={setFramework}
        onFilterFrom={setFrom}
        onFilterTo={setTo}
      />
      {isLoading && enabled && (
        <Skeleton
          style={{ marginTop: "20px" }}
          active
          avatar
          paragraph={{ rows: 4 }}
        />
      )}
      {!enabled && (
        <Alert
          style={{ marginTop: "20px" }}
          message="Please select a fund, companies, framework and time period to see insights"
          type="info"
          showIcon
        />
      )}
      {tableData && !isLoading ? (
        <InsightsTable
          rows={tableData.rows}
          title={tableData.title}
          companies={tableData.companies}
          companyIds={tableData.companyIds}
          rowScopes={tableData.rowScopes}
          metric2description={tableData.metric2description}
          timePeriod={tableData.timePeriod}
        />
      ) : (
        <></>
      )}
    </>
  );
};

function createCategories(
  categories,
  tableData,
  rowScopes,
  metric2description
) {
  categories.forEach(function (category, index) {
    //console.log("Processing category: " + category.title); // index
    createMetrics(category, tableData, metric2description);
    rowScopes.push(category.metrics.length);

    for (
      var metricIndex = 1;
      metricIndex < category.metrics.length;
      metricIndex++
    ) {
      rowScopes.push(0);
    }
  });
}

function createMetrics(category, tableData, metric2description) {
  category.metrics.forEach(function (metric, index) {
    metric2description[metric.title] = metric.description;
    //console.log("Processing metric: " + metric.title); // index
    createCompanyData(category, metric, tableData);
  });
}

function createCompanyData(category, metric, tableData) {
  const row = {
    key: metric.id,
    area: category.title,
    metric: metric.title,
  };
  const companies = metric.data;

  for (const [key, value] of Object.entries(companies)) {
    //console.log("Company:", key, JSON.stringify(value));
    row[key] = value;
  }
  tableData.push(row);
}

export default Insights;
