import { ConfigProvider, Table, Typography, Image } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Link, useNavigate } from "react-router-dom";
import Icon from "@ant-design/icons";
import { ReactComponent as Arrow } from "@icons/arrow-right-line.svg";
import { formatDate, formatNumber } from "src/utils";

const { Text } = Typography;

export interface PortfolioTableRowDataType {
  key: string;
  id: string;
  company: string;
  icon: string;
  year: number;
  industry: string;
  headcount: number;
  investmentDate: string;
  amtFunded: number;
  revenue: number;
  ebitda: number;
}

const columns: ColumnsType<PortfolioTableRowDataType> = [
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    render: (_, record) => (
      <div className='flex items-center'>
        <Image
          width="42px"
          style={{ paddingLeft: "8px", paddingRight: "8px" }}
          preview={false}
          src={record.icon}
        />
        <Link
          style={{ fontSize: "12px", fontWeight: 600, color: "#4B5563" }}
          to={`/portfolio/details/${record.id}`}
        >
          {record.company}
        </Link>
      </div>
    ),
  },
  {
    title: "Year Founded",
    dataIndex: "year",
    key: "year",
    sorter: (a, b) => a.year - b.year,
  },
  {
    title: "Industry",
    dataIndex: "industry",
    key: "industry",
  },
  {
    title: "Headcount",
    dataIndex: "headcount",
    key: "headcount",
    responsive: ["md"],
    render: (_, record) => formatNumber(record.headcount),
    sorter: (a, b) => a.headcount - b.headcount,
  },
  {
    title: "Investment Date",
    dataIndex: "investmentDate",
    key: "investmentDate",
    responsive: ["lg"],
    render: (_, record) => formatDate(record.investmentDate),
  },
  {
    title: "Amt. Funded ($)",
    dataIndex: "amtFunded",
    key: "amtFunded",
    responsive: ["lg"],
    render: (_, record) => formatNumber(record.amtFunded),
    sorter: (a, b) => a.amtFunded - b.amtFunded,
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    key: "revenue",
    responsive: ["lg"],
    render: (_, record) => formatNumber(record.revenue),
    sorter: (a, b) => a.revenue - b.revenue,
  },
  {
    title: "EBITDA($)",
    dataIndex: "ebitda",
    key: "ebitda",
    responsive: ["lg"],
    render: (_, record) => formatNumber(record.ebitda),
    sorter: (a, b) => a.ebitda - b.ebitda,
  },
];

type PortfolioTableProps = {
  dataSource: PortfolioTableRowDataType[];
};
export const PortfolioTable = ({ dataSource }: PortfolioTableProps) => {
  const navigate = useNavigate();

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontSize: 12,
            borderRadius: 12,
          },
        }}
      >
        <div
          style={{ textAlign: "left", marginBottom: "20px", marginTop: "20px" }}
        >
          <Text
            style={{
              textAlign: "left",
              color: "#2D324E",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Portfolio
          </Text>
          <div
            style={{
              marginTop: "10px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "lightgray",
              borderRadius: "5px",
            }}
          >
            <Table
              style={{ fontSize: "16px", fontWeight: 500, color: "#4B5563" }}
              dataSource={dataSource}
              columns={columns}
              bordered={false}
              pagination={false}
            />
          </div>
          <a
            className="detail"
            href="/portfolio/details/1"
            onClick={(e) => {
              e.preventDefault();
              navigate("/portfolio/details/1");
            }}
          >
            View Portfolio
            <Icon
              style={{ fontSize: "150%", marginLeft: "8px" }}
              component={Arrow}
            />
          </a>
        </div>
      </ConfigProvider>
    </>
  );
};
