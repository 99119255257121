import "./App.css";
import { Amplify } from "aws-amplify";

import "@aws-amplify/ui-react/styles.css";
import { Layout, ConfigProvider } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import axios from "axios";

import log, { LogLevelNumbers } from "loglevel";

import Login from "@components/Login";
import Dashboard from "@screens/Dashboard";
import Print from "@screens/Print";

import { Portfolio, Detail as PortfolioDetail } from "@screens/Portfolio";
import InsightsPage from "@screens/InsightsPage";
import Goals from "@screens/Goals";
import FundReports from "@screens/FundReports";
import CompanyReports from "@screens/CompanyReports";
import FundDetails from "@screens/FundDetails";
import { FundProvider } from "@components/ContextProviders/FundContext";

import ContextProviders from "@components/ContextProviders";
import THEME from "@constants/theme";
import useAuth from "@hooks/useAuth";
import ErrorPage from "@screens/ErrorPage";
import { COLOR_GRAY_LIGHT } from "@constants/colors";
import TokenAuthContext from "@components/ContextProviders/TokenAuthContext";
import { useContext } from "react";

try {
  const level: LogLevelNumbers = 1; //process.env.REACT_APP_LOG_LEVEL;

  console.log("Log level set to " + level);
  log.setLevel(level);
} catch (error) {
  console.log("LOG LEVEL COULD NOT BE READ");
}

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    mandatorySignIn: true,
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "portfolio",
    element: <Portfolio />,
  },
  {
    path: "portfolio/details/:id",
    element: <PortfolioDetail />,
  },
  {
    path: "portfolio/details/:id?/:section",
    element: <PortfolioDetail />,
  },
  {
    path: "insights",
    element: <InsightsPage />,
  },
  {
    path: "goals",
    element: <Goals />,
  },
  {
    path: "fundreports",
    element: <FundReports />,
  },
  {
    path: "funddetails",
    element: <FundDetails />,
  },
  {
    path: "companyreports",
    element: <CompanyReports />,
  },
  {
    path: "print",
    element: <Print />,
  },
]);

function App() {
  return (
    <ContextProviders>
      <BaseRoutes />
    </ContextProviders>
  );
}

function BaseRoutes() {
  const { user } = useAuth();
  

  return (
    <ConfigProvider
      theme={{
        token: {
          colorLink: THEME.COLOR_LINK,
          fontFamily: "Inter",
          borderRadius: 5,
          colorBorder: COLOR_GRAY_LIGHT,
        },
      }}
    >
      {
        (user === undefined) ? <Login /> :
      
        <FundProvider>
          <Layout>
            <RouterProvider router={router} />
          </Layout>
        </FundProvider>
      }
    </ConfigProvider>
  );
}
export default App;
