import { Table, ConfigProvider, Typography, Tabs, Image } from "antd";
import { useState, useEffect } from "react";
import { COLOR_GRAY_LIGHT, COLOR_DARK_PRIMARY, COLOR_BRAND_PRIMARY, COLOR_TYPOGRAPHY_MID } from "@constants/colors";
import { imageUrl } from "src/utils";

const { Text } = Typography;

const fakeFinancials = [
  {
    key: 'Company1',
    name: 'Herd',
    image: 'cotopaxi.png',
    years: [
      {
        year: '2020',
        sales: '$50.4',
        ebitda: '35.6',
        netIncome: '15.2'
      },
      {
        year: '2021',
        sales: '$57.7',
        ebitda: '34.6',
        netIncome: '23.5'
      },
      {
        year: '2022',
        sales: '64.3',
        ebitda: '32',
        netIncome: '32.1'
      },
    ]
  },
  {
    key: 'Company2',
    name: 'LevelUp',
    image: 'cotopaxi.png',
    years: [
      {
        year: '2020',
        sales: '55.4',
        ebitda: '38',
        netIncome: '16.6'
      },
      {
        year: '2021',
        sales: '61.8',
        ebitda: '37',
        netIncome: '24.7'
      },
      {
        year: '2022',
        sales: '83.18',
        ebitda: '41',
        netIncome: '41.5'
      },
    ]
  },
  {
    key: 'Company3',
    name: 'Mandala',
    image: 'cotopaxi.png',
    years: [
      {
        year: '2020',
        sales: '55.4',
        ebitda: '38',
        netIncome: '16.6'
      },
      {
        year: '2021',
        sales: '61.8',
        ebitda: '37',
        netIncome: '24.7'
      },
      {
        year: '2022',
        sales: '83.1',
        ebitda: '41',
        netIncome: '41.5'
      },
    ]
  },
  {
    key: 'total',
    name: 'Total',
    years: [
      {
        year: '2020',
        sales: '999',
        ebitda: '999',
        netIncome: '999'
      },
      {
        year: '2021',
        sales: '999',
        ebitda: '999',
        netIncome: '999'
      },
      {
        year: '2022',
        sales: '999',
        ebitda: '999',
        netIncome: '999'
      },
    ]
  }
];

const basicColumns = [
  {
    title: 'Company',
    dataIndex: 'name',
    key: 'id',
    render: (text, record) => {

      if (text === 'Total')
        return <div style={{ fontWeight: 600, textAlign: "right" }}><Text>Total</Text></div>

      return (
        <>
          <Image
            style={{ paddingLeft: "0px", marginRight: "10px", height:"25px", width:"25px" }}
            preview={false}
            src={imageUrl(record.image)}
          />
          <span style={{ paddingLeft: "10px", fontWeight: 600, color: COLOR_TYPOGRAPHY_MID }}>{text}</span>
        </>
      );
    }
  }
];

//ASSUMPTIONS: 
//1. there is at least one company
//2. all companies have same columns (ie years or process of years)
//3. if missing data for a given year will still be a column but blank or null
//4. total column is last item in list
//5. formatting done on server
function createColumns(sourceData) {

  const columns0 = [...basicColumns];
  const columns1 = [...basicColumns];
  const columns2 = [...basicColumns];

  const example = sourceData[0];
  var index = 0;

  for (var year of example.years) {
    createColumn(columns0, year, index, "sales");
    createColumn(columns1, year, index, "ebitda");
    createColumn(columns2, year, index, "netIncome");

    index++;
  }

  return [columns0, columns1, columns2];
}

function createColumn(columns, year, index, target) {

  columns.push({
    title: year.year,
    key: year.year,
    render: (_, record) => {
      const res = record.years[index][target];
      if (record.key === 'total')
        return <Text style={{ fontWeight: 600, textAlign: "right" }}>{res}</Text>;

      return <Text>{res}</Text>;
    }
  });
}

function createTable(data, columns) {

  return <Table style={{ fontSize: '16px', fontWeight: 500, color: '#4B5563', padding: '0px' }}
    dataSource={data}
    columns={columns}
    bordered={true}
    size="small"
    pagination={false} />
}

function FinancialsTable() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (items.length === 0) {
      const columns = createColumns(fakeFinancials);

      setItems([
        {
          key: '1',
          label: "Revenue",
          children: createTable(fakeFinancials, columns[0]),
        },
        {
          key: '2',
          label: "PF Adj. EDITDA",
          children: createTable(fakeFinancials, columns[1]),
        },
        {
          key: '3',
          label: "PF Adj. EDITDA vs. UW",
          children: createTable(fakeFinancials, columns[2]),
        },
      ]);
    }
  }, [setItems, items.length])

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
          borderRadius: 12,
        },
        components: {
          Tabs: {
            colorPrimary: COLOR_BRAND_PRIMARY,
            fontSize: 16,
            colorPrimaryHover: COLOR_DARK_PRIMARY,
            colorText: COLOR_GRAY_LIGHT
          },
        },
      }}
    >
      <div style={{ textAlign: "left", marginBottom: "20px", marginTop: '20px' }} >
        <Text style={{ textAlign: "left", color: '#2D324E', fontSize: '16px', fontWeight: 600 }} >
          Financials
        </Text>
        <Tabs defaultActiveKey="1" items={items} style={{ fontWeight: 500 }} />
      </div>
    </ConfigProvider>
  );
};

export default FinancialsTable