import {
  ConfigProvider,
  Table,
  Typography,
  Radio,
  Row,
  Col,
  Image,
  Tooltip,
  RadioChangeEvent,
} from "antd";
import { useState, useEffect, useMemo } from "react";
import Icon from "@ant-design/icons";
//import { ReactComponent as Overall } from "@icons/Indicator_overall.svg";
import { ReactComponent as Funds } from "@icons/funds-line.svg";
import { ReactComponent as Available } from "@icons/Indicator_available.svg";
import { ReactComponent as Unavailable } from "@icons/Indicator_unavailable.svg";
import { ReactComponent as Verified } from "@icons/Indicator_verified.svg";
import { ReactComponent as fundIcon } from "@logos/BCSF.svg";
import { ReactComponent as Vector } from "@icons/Vector.svg";
import { ReactComponent as CheckBox } from "@icons/checkbox-circle-line.svg";
import log from "loglevel";

const { Text } = Typography;

// TODO - move to constants and a more functional approach. Avoid mutating global variables. LG
var columns0;
var columns1;
var columns2;

function formatImage(value) {
  if (value === "AVAILABLE")
    return <Icon style={{ fontSize: "130%" }} component={Available} />;
  if (value === "VERIFIED")
    return <Icon style={{ fontSize: "130%" }} component={Verified} />;
  if (value === "NOT-AVAILABLE")
    return <Icon style={{ fontSize: "130%" }} component={Unavailable} />;
  if (value === "NOT_AVAILABLE")
    return <Icon style={{ fontSize: "130%" }} component={Unavailable} />;

  return <>n/a</>;
}

//ASSUMPTION: i can trust data to list same set of companies in each metric
function createColumns(companies, basicColumns, companyIds) {
  columns0 = [...basicColumns];
  columns1 = [...basicColumns];
  columns2 = [...basicColumns];

  companies
    .map((company: any) => {
      return [
        createColumn(columns0, company, "completion", true, companyIds),
        createColumn(columns1, company, "performance", false, companyIds),
        createColumn(columns2, company, "improvements", false, companyIds),
      ];
    })
    .flat();
}

function createColumn(columns, key, field, useImage, companyIds) {
  const header = (
    <span className="flex items-center">
      <Image
        width="32px"
        style={{ paddingRight: "8px" }}
        preview={false}
        src={companyIds[key].profileUrl}
      />
      {companyIds[key].name}
    </span>
  );

  columns.push({
    title: () => header,
    dataIndex: key,
    key: key,
    render: (_, record, rowIndex) => {
      if (useImage) return <>{formatImage(record[key][field])}</>;
      if (record[key][field] === null)
        return <>{formatImage("NOT-AVAILABLE")}</>;
      return <>{record[key][field]}</>;
    },
  });
}

export interface RowType {
  key: string;
  area: string;
  metrics: string;
}

type InsightsTableProps = {
  rows: RowType[];
  title: string;
  companies: string[];
  companyIds: {};
  rowScopes: number[];
  metric2description: {};
  timePeriod: string;
};

export const InsightsTable = ({
  rows,
  title,
  companies,
  companyIds,
  rowScopes,
  metric2description,
  timePeriod,
}: InsightsTableProps) => {
  log.debug("ROWS:", rows);
  log.debug("COMPANIES:", companies);
  log.debug("SCOPES:", rowScopes);
  log.debug("metric2description:", metric2description);

  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const basicColumns = useMemo(
    () => [
      {
        title: "Area",
        dataIndex: "area",
        key: "area",
        rowScope: "row",
        render: (text, record, rowIndex) => {
          return (
            <Text style={{ fontSize: "12px", fontWeight: 500 }}>{text}</Text>
          );
        },
        onCell: (record, index) => {
          return { rowSpan: rowScopes[index] };
        },
      },
      {
        title: "Metric Details",
        dataIndex: "metric",
        key: "metric",
        render: (text, record, rowIndex) => {
          return (
            <Tooltip
              color="#00A2AC"
              placement="topLeft"
              title={metric2description[record.metric]}
            >
              {text}
            </Tooltip>
          );
        },
      },
    ],
    [metric2description, rowScopes]
  );

  useEffect(() => {
    createColumns(companies, basicColumns, companyIds);
    setTableData(rows);
    setColumns(columns0);
  }, [rows, companies, companyIds, basicColumns]);

  const viewChanged = (event: RadioChangeEvent): void => {
    const { value } = event.target;
    if (value === "Performance") setColumns(columns1);
    else if (value === "Improvements") setColumns(columns2);
    else setColumns(columns0);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontSize: 12,
            borderRadius: 12,
          },
          components: {
            Radio: {
              colorPrimary: "#0CC2CB",
            },
          },
        }}
      >
        <div
          style={{ textAlign: "left", marginBottom: "20px", marginTop: "20px" }}
        >
          <Text
            style={{
              textAlign: "left",
              color: "#2D324E",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Insights
          </Text>

          {tableData.length > 0 ? (
            <div
              style={{
                marginTop: "10px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "lightgray",
                borderRadius: "5px",
              }}
            >
              <Table
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#4B5563",
                  padding: "0px",
                }}
                dataSource={tableData}
                columns={columns}
                bordered={false}
                size="small"
                pagination={false}
                title={() => (
                  <Row gutter={12}>
                    <Col>
                      <Icon
                        style={{ paddingTop: "5px", fontSize: "200%" }}
                        component={fundIcon}
                      />
                    </Col>
                    <Col>
                      <Row>
                        <Text
                          style={{
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "#222222",
                          }}
                        >
                          {title}
                        </Text>
                      </Row>
                      <Row>
                        <Text
                          style={{
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "#777777",
                          }}
                        >
                          {timePeriod}
                        </Text>
                      </Row>
                    </Col>
                    <Col flex="auto"></Col>
                    <Col>
                      <Radio.Group
                        style={{ fontWeight: 600 }}
                        defaultValue="Completion"
                        onChange={viewChanged}
                      >
                        <MetricRadio value="Completion" icon={CheckBox} />
                        <MetricRadio value="Performance" icon={Vector} />
                        <MetricRadio value="Improvements" icon={Funds} />
                      </Radio.Group>
                    </Col>
                  </Row>
                )}
                footer={() => (
                  <Row justify="end">
                    <Icon
                      style={{ fontSize: "150%", marginRight: "4px" }}
                      component={Unavailable}
                    />
                    Data: Not Available
                    <Icon
                      style={{
                        fontSize: "150%",
                        marginLeft: "15px",
                        marginRight: "4px",
                      }}
                      component={Available}
                    />
                    Data: Available
                    <Icon
                      style={{
                        fontSize: "150%",
                        marginLeft: "15px",
                        marginRight: "4px",
                      }}
                      component={Verified}
                    />
                    Data: Verified
                  </Row>
                )}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </ConfigProvider>
    </>
  );
};

function MetricRadio(props) {
  return (
    <Radio.Button value={props.value}>
      <Row>
        <Icon
          style={{ fontSize: "130%", marginRight: "4px" }}
          component={props.icon}
        />
        {props.value}
      </Row>
    </Radio.Button>
  );
}
