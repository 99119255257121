import Page from "@screens/Page";
import { Summary } from "@components/Summary";
import { Modules } from "@components/Modules";
import { PortfolioTable } from "@components/PortfolioTable";
import Insights from "@components/Insights";
import { useNavigate } from "react-router-dom";
import useActiveFund from "@hooks/useActiveFund";
import useDashboardQuery from "@hooks/api/useDashboardQuery";
import { portfolioRowTransform } from "./Portfolio/Portfolio";

const Dashboard = () => {
  const navigate = useNavigate();
  const { activeFundId } = useActiveFund();
  const { data } = useDashboardQuery(activeFundId);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function print() {
    navigate("/print");
  }

  return (
    <Page siderIndex="0">
      <Summary />
      <Modules />
      <PortfolioTable
        dataSource={(data?.portfolios || []).map(portfolioRowTransform)}
      />
      <div className="pt-8" />
      <Insights />
    </Page>
  );
};

export default Dashboard;
