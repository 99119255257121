import {
  Image,
  Table,
  Col,
  ConfigProvider,
  Row,
  Skeleton,
  Tag,
  Tabs,
  Typography,
  Progress,
} from "antd";
import Page from "@screens/Page";
import FinancialsTable from "@components/FinancialsTable";
import useFundQuery from "@hooks/api/useFundQuery";
import { COLOR_DARK_PRIMARY } from "@constants/colors";
import { S3_URL } from "@constants/urls";
import type { ColumnsType } from "antd/es/table";
import PeopleTable from "@components/PeopleTable";
import log from "loglevel";
import useActiveFund from "@hooks/useActiveFund";

const { Text } = Typography;

const FundDetail = () => {
  const { activeFundId } = useActiveFund();
  const { data, isLoading } = useFundQuery(activeFundId);
  const imageURL = S3_URL + "logos/SDG.svg";

  log.debug("FUND DATA: ", data);

  return (
    <Page siderIndex="-1">
      <ConfigProvider
        theme={{
          components: {},
          token: {
            fontSize: 12,
            borderRadius: 12,
          },
        }}
      >
        {isLoading ? (
          <Skeleton active={isLoading} />
        ) : (
          <>
            <Row>
              <Col>
                <Image preview={false} height="48px" src={imageURL} />
              </Col>
              <Col>
                <Row>
                  <Text
                    style={{
                      fontWeight: 700,
                      color: COLOR_DARK_PRIMARY,
                      fontSize: "18px",
                    }}
                  >
                    {data.name}
                  </Text>
                </Row>
                <Row>
                  <a
                    style={{ fontWeight: 500, color: "#0078AF" }}
                    href={data.website}
                  >
                    {data.website}
                  </a>
                </Row>
              </Col>
            </Row>
            <div style={{ marginTop: "20px" }}>
              <FundOverview
                geographies={data.locationOfInvestments}
                classes={data.investorTypes}
                thesis={data.mission}
                sectors={data.industrySectors}
                frameworks={data.frameworks}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <Goals
                overarching={data.overarchingGoals}
                vertical={data.verticalGoals}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <PeopleTable
                label={"Investment Team"}
                list={data.investmentTeam}
              />
            </div>
            <div style={{ marginTop: "0px" }}>
              <PeopleTable
                label={"Advisory Board"}
                list={data.advisoryBoard}
              />
            </div>
            <div style={{ marginTop: "0px" }}>
              <FinancialsTable />
            </div>
          </>
        )}
      </ConfigProvider>
    </Page>
  );
};

function FundOverview(props) {
  return (
    <div>
      <Row>
        <Text style={{ fontWeight: 600, fontSize: "16px" }}>Fund Overview</Text>
      </Row>
      <Row style={{ marginTop: "15px" }} gutter={15}>
        <Col span={12}>
          <Thesis
            thesis={props.thesis}
            geographies={props.geographies}
            classes={props.classes}
            sectors={props.sectors}
          />
        </Col>
        <Col span={12}>
          <ReportingFrameworks frameworks={props.frameworks} />
        </Col>
      </Row>
    </div>
  );
}

function Thesis(props) {
  return (
    <div
      style={{
        padding: "10px",
        height: "100%",
        borderStyle: "solid",
        borderColor: "#E5E7EB",
        borderRadius: "5px",
      }}
    >
      <Row>
        <Text
          style={{ fontWeight: 600, fontSize: "12px", marginBottom: "10px" }}
        >
          Thesis
        </Text>
      </Row>
      <Text>{props.thesis}</Text>
      <Row gutter={15}>
        <ThesisTags label="Asset Class" list={props.classes} color="volcano" />
        <ThesisTags
          label="Geographies"
          list={props.geographies}
          color="purple"
        />
        <ThesisTags label="Sectors" list={props.sectors} color="blue" />
      </Row>
    </div>
  );
}

function ThesisTags(props) {
  return (
    <Col style={{ marginTop: "15px" }}>
      <Row>
        <Text style={{ fontWeight: 600 }}>{props.label}</Text>
      </Row>
      {props.list.map((item, index) => (
        <Tag
          key={index}
          style={{
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingLeft: "8px",
            paddingRight: "8px",
            fontWeight: 500,
            marginTop: "8px",
            borderRadius: "15px",
            fontSize: "12px",
          }}
          color={props.color}
        >
          {item.name}
        </Tag>
      ))}
    </Col>
  );
}

function ReportingFrameworks(props) {
  return (
    <div
      style={{
        padding: "10px",
        height: "100%",
        borderStyle: "solid",
        borderColor: "#E5E7EB",
        borderRadius: "5px",
      }}
    >
      <Row>
        <Text
          style={{ fontWeight: 600, fontSize: "12px", marginBottom: "10px" }}
        >
          Reporting Frameworks
        </Text>
      </Row>
      <Row gutter={15}>
        {props.frameworks.map((framework) => (
          <div key={framework.id} style={{ margin: "8px" }}>
            <Image width="128px" key={framework.name} src={framework.imageUrl} />
          </div>
        ))}
      </Row>
    </div>
  );
}

function augmentGoals(goal) {
  goal.key = goal.id;
  goal.currentValue = parseInt(goal.currentValue);
  goal.targetDate = goal.targetDate.substring(0, 4);
}

function Goals(props) {
  log.debug("GOALS:", props);

  props.overarching.map(augmentGoals);

  props.vertical.verticals.map((vertical) => {
    //log.debug("GOAL:", vertical);
    return vertical.goals.map(augmentGoals);
  });

  return (
    <>
      {props.vertical === undefined ? (
        <></>
      ) : (
        <Row gutter={15}>
          <Col span={12}>
            <Row>
              <Text style={{ fontWeight: 600, color: COLOR_DARK_PRIMARY }}>
                Overarching Fund Goals
              </Text>
            </Row>
            <Table
              dataSource={props.overarching}
              columns={goalColumns}
              size="small"
              bordered
            />
          </Col>
          <Col span={12}>
            <Row>
              <Text style={{ fontWeight: 600, color: COLOR_DARK_PRIMARY }}>
                Vertical Specific Goals
              </Text>
            </Row>
            <Tabs
              type="card"
              items={props.vertical.verticals.map((goal, index) => {
                return {
                  label: goal.name,
                  key: index,
                  children: (
                    <Table
                      key={goal.goals.id}
                      dataSource={goal.goals}
                      columns={goalColumns}
                      size="small"
                      bordered
                    />
                  ),
                };
              })}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

interface GoalDataType {
  key: string;
  name: string;
  currentValue: number;
  target: string;
  targetDate: string;
}

const goalColumns: ColumnsType<GoalDataType> = [
  {
    title: "Metric",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Progress",
    dataIndex: "currentValue",
    key: "currentValue",
    render: (_, record) => (
      <>
        <Progress percent={record.currentValue} />
      </>
    ),
  },
  {
    title: "Goal",
    dataIndex: "target",
    key: "target",
  },
  {
    title: "Target Year",
    dataIndex: "targetDate",
    key: "targetDate",
  },
];

export default FundDetail;
