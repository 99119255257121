import { ConfigProvider, Select } from "antd";

type FilterDropdownProps = {
  label?: string;
  options: { value: string; label: string }[];
  values?: string[];
  onChange?: (value: string[]) => void;
  isLoading?: boolean;
};

export const FilterDropdown = ({
  label,
  values,
  options,
  onChange,
  isLoading = false,
}: FilterDropdownProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
          borderRadius: 5,
          controlHeight: 45,
        },
      }}
    >
      <Select
        showSearch
        mode="tags"
        loading={isLoading}
        style={{
          width: "100%",
          textAlign: "left",
          margin: "10px 0",
          minWidth: "150px",
        }}
        dropdownStyle={{ width: "100%" }}
        placeholder={label}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={options}
        value={values}
        onChange={onChange}
      />
    </ConfigProvider>
  );
};

export default FilterDropdown;
