
import { Skeleton, Table, Image } from "antd";
import { ColumnsType } from "antd/es/table";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import { Link } from "react-router-dom";


export interface GoalDataType {
  key: string;
  name: string;
  investmentYear: string;
  icon: string;
}

const goalColumns: ColumnsType<GoalDataType> = [
  {
    title: <div style={{ fontWeight:600 }}>Portfolio</div>,
    dataIndex: "name",
    key: "name",
    render: (text, record, rowIndex) => {
      return (
        <div className='flex items-center'>
          <Image
            width="42px"
            style={{ paddingLeft: "8px", paddingRight: "8px" }}
            preview={false}
            src={record.icon}
          />
          <Link
            style={{ fontSize: "12px", fontWeight: 600, color: "#4B5563" }}
            to={`/portfolio/details/${record.key}`}
          >
            {text}
          </Link>
        </div>
      );
    },
  },
  {
    title: <div style={{ fontWeight:600 }}>Inv. Year</div>,
    dataIndex: "investmentYear",
    key: "investmentYear",
  },

];

export const PerformanceTable = (props) => {

  console.log("PerformanceTable", props.companies);

  return (
    <>
    {
      (performance) 
      ?
      <div
      style={{
        marginTop: "10px",

      }}
      >
        <Table
          style={{color:'#4B5563'}}
          dataSource={props.companies}
          columns={goalColumns}
          size="small"
          bordered={true}
          pagination={false}
        />
      </div>
      :
      <Skeleton/>
    }
    </>
  );
};

export default PerformanceTable;
