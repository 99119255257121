import { Skeleton } from "antd";
import Page from "@screens/Page";
import { Typography, Image, Divider } from "antd";
import ReportList from "@components/ReportList"
import useActiveFund from "@hooks/useActiveFund";
import useReportsQuery from "@hooks/api/useReportsQuery";
import log from "loglevel";

const { Text } = Typography;

const Reports = () => {
  const { activeFundId } = useActiveFund();
  const { data: frameworkReports } = useReportsQuery(activeFundId);
  const { data: companyReports } = useReportsQuery(activeFundId, true);

  log.debug("Framework reports:", frameworkReports);
  log.debug("Company reports:", companyReports);

  return (
    <Page siderIndex="4.2">
      <div className='mb-4'>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "left",
            color: "#1F2937",
          }}
        >
          Reports
        </Text>
        <Divider className='h-5' type="vertical"/>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: 500,
            textAlign: "left",
            color: "#489FAA",
          }}
        >
          Company Level
        </Text>
      </div>

      {companyReports ? (
        Object.keys(companyReports).map((key, index) => {
          return <CompanyReports company={companyReports[key]} key={index} />;
        })
      ) : (
        <Skeleton />
      )}
    </Page>
  );
};

export function CompanyReports(props) {
  const companyDetails = props.company.company;
  const reports = props.company.reports;

  return (
    <div
      style={{
        padding: "0px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "lightgray",
        borderRadius: "15px",
      }}
    >
      <div style={{ margin: "8px" }}>
        <div className="flex items-center" style={{ padding: "20px" }}>
          <Image width="64px" preview={false} src={companyDetails.profileUrl} />
          <Text
            style={{ marginLeft: "10px", fontSize: "16px", fontWeight: 600 }}
          >
            {companyDetails.displayName}
          </Text>
        </div>
        <ReportList
          icon="/images/Finance_file.png"
          title="Financial Statements"
          reports={reports["Financial Statements"]}
        />
        <ReportList
          icon="/images/B_impact_file.png"
          title="B Impact Assessment"
          reports={reports["B Impact Assessment"]}
        />
        <ReportList
          icon="/images/GRI_file.png"
          title="GRI"
          reports={reports["GRI"]}
        />
      </div>
    </div>
  );
}



export default Reports;
