import useApiQuery from "@hooks/api/useApiQuery";
import { ReactNode, createContext, useEffect, useState } from "react";

const FundContext = createContext(undefined);

type FundContextProps = {
  fundId?: string | null;
  children?: ReactNode;
};

export const FundProvider = ({ fundId, children }: FundContextProps) => {
  const [activeFundId, setActiveFundId] = useState<string | null>(fundId);
  const { data: funds }: { data: any } = useApiQuery("funds");

  // Set the active fund id to the first fund in the list
  useEffect(() => {
    if (activeFundId === null || activeFundId === undefined) {
      if (funds) {
        setActiveFundId(funds.data[0].id);
      }
    }
  }, [activeFundId, funds]);

  const value = {
    activeFundId,
    setActiveFundId,
  };
  return <FundContext.Provider value={value}>{children}</FundContext.Provider>;
};

export default FundContext;
