import {
  Skeleton,
} from "antd";
import log from "loglevel";
import {useState, useEffect} from "react";

import {FundGoal} from "@components/Goals/FundGoal";

type Props = {
  goals: Map<string, any>;
};

export const StackedGoals = ({ goals }: Props) => {
  const [fundGoals, setFundGoals] = useState(null);


  useEffect(() => {
    
    if (fundGoals === null) {
      log.debug("Stacked GOALS:", goals);

      const newFundGoals = [];
      
      goals.forEach((goal,key) => {
        newFundGoals.push(<div key={key}><FundGoal goal={goal}/></div>);
      });

      setFundGoals(newFundGoals);
    }
    return () => {
    };
  }, [goals]);

  return (
    <>
      {
        (fundGoals) 
        ?
        <>
          {fundGoals}
        </>
        
        :
        <Skeleton />
      }
    </>
  );
}

