import { COLOR_BRAND_PRIMARY, COLOR_GRAY_LIGHT } from "@constants/colors";
import { Avatar, Card, Space, Tooltip, Typography, Row, Col } from "antd";
import { ReactNode } from "react";
import { imageUrl } from "src/utils";
const { Text } = Typography;

type RoleMember = {
  name: string;
  avatar?: ReactNode;
  src?: string;
};

type Role = {
  title: string;
  avatar?: ReactNode;
  members?: RoleMember[];
};

type RoleCardProps = {
  role: Role;
  icon: ReactNode;
  onClick?: () => void;
};

const initials = (name: string): string =>
  name
    .split(" ")
    .map((n) => n[0])
    .join("");

export const RoleCard = ({ icon, role, onClick }: RoleCardProps) => {
  const { title, members = [] } = role;

  return (
    <Card
      onClick={onClick}
      headStyle={{ border: 0, fontSize: "12", fontWeight: 600 }}
      hoverable
      style={{ maxHeight: "125px" }}
      bodyStyle={{ padding:'8px' }}
    >
      <div className='grid grid-rows-2'>

        <Row justify="start" className="flex items-center">
          <Col><Text className='font-semibold'>{title}</Text></Col>
          <Col flex="auto"></Col>
          <Col>{icon ? icon : <></>}</Col>
        </Row>
        <RoleCardMembers members={members} />
      </div>
    </Card>
  );
};

const TextLabel = ({ text }: { text: string }) => (
  <Typography.Text
    style={{ fontSize: "12px", fontWeight: "500", color:'#76798B'}}
  >
    {text}
  </Typography.Text>
);

export const RoleCardMembers = ({ members }: { members: RoleMember[] }) => {
  return (
    <div>
      {members.length > 1 ? (
        <div>
          <Space>
            <Avatar.Group
              maxCount={4}
              maxPopoverTrigger="click"
              size="small"
              maxStyle={{
                color: COLOR_BRAND_PRIMARY,
                backgroundColor: COLOR_GRAY_LIGHT,
                cursor: "pointer",
              }}
            >
              {members.map((member, index) => {
                const key = `member-${index}`;
                if (member.src) {
                  return (
                    <Tooltip key={key} title={member.name} placement="top">
                      <Avatar
                        src={imageUrl(member.src)}
                        style={{ backgroundColor: COLOR_BRAND_PRIMARY }}
                      >
                        {initials(member.name)}
                      </Avatar>
                    </Tooltip>
                  );
                }

                if (member.avatar) {
                  return (
                    <Tooltip key={key} title={member.name} placement="top">
                      <Avatar style={{ backgroundColor: COLOR_BRAND_PRIMARY }}>
                        {initials(member.name)}
                      </Avatar>
                    </Tooltip>
                  );
                }

                return (
                  <Tooltip key={key} title={member.name} placement="top">
                    <Avatar style={{ backgroundColor: COLOR_BRAND_PRIMARY }}>
                      {initials(member.name)}
                    </Avatar>
                  </Tooltip>
                );
              })}
            </Avatar.Group>
            <TextLabel text={`${members.length} members`} />
          </Space>
        </div>
      ) : 
        
          members.length === 1 ?
          <div>
            <Avatar
              src={members[0].src}
              size="small"
              style={{ backgroundColor: COLOR_BRAND_PRIMARY }}
            >
              {initials(members[0].name)}
            </Avatar>
            {<TextLabel text={members[0].name} />}
          </div>
          :
          <TextLabel text={`${members.length} members`} />
      }
    </div>
  );
};

export default RoleCard;
