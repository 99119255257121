/**
 * @author  Regimag.in
 * @description  This hook is used fetch users/me data
 *
 */
import { useQuery } from "@tanstack/react-query";
import useApiClient from "@hooks/useApiClient";
import useAuth from "@hooks/useAuth";
import type { UseQueryOptions } from "@tanstack/react-query";
import log from "loglevel";

export interface ApiQueryOptions {
  queryParams?: any;
  method?: string;
  transform?: (data: any) => any;
}
export type ApiQueryProps = ApiQueryOptions & UseQueryOptions;

const useApiQuery = (url: string, options?: ApiQueryProps) => {
  const {
    method = "GET",
    queryKey = [url],
    queryParams,
    enabled: explicitlyEnabled,
    ...useQueryOptions
  } = options || {};
  const apiClient = useApiClient();

  const { isSignedIn } = useAuth();
  const params = queryParams || {};
  const cacheKey = Array.isArray(queryKey) ? queryKey : Array(queryKey);

  const enabled =
    (explicitlyEnabled !== undefined ? explicitlyEnabled : isSignedIn) &&
    !!apiClient;
  console.log("useApiQuery", url, params, cacheKey, enabled, useQueryOptions);
  return useQuery(
    cacheKey,
    async () => {
      log.debug(
        "useApiQuery, will make request ",
        method,
        url,
        params,
        apiClient
      );

      const result = await apiClient.request({ method, url, params });
      if (options?.transform) {
        return options.transform(result);
      }
      return result;
    },
    {
      enabled,
      ...useQueryOptions,
    }
  );
};

export default useApiQuery;
