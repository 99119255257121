import { ReactNode, createContext, useEffect, useState } from "react";
import { Hub, Auth } from "aws-amplify";
import log from "loglevel";

const SIGNIN = "SQ_sign_in";

type AuthContextValue = {
  user: string | undefined;
  getJwtToken: () => Promise<string>;
  isSignedIn: boolean;
  signOut: (context: any, history: any) => void;
};

export function addSignInListener(listener) {
  Hub.listen(SIGNIN, listener);
}
export function removeSignInListener(listener) {
  Hub.remove(SIGNIN, listener);
}

export function signOut(context, history) {
  try {
    Auth.signOut();
    context = undefined;
    history.push("/");
  } catch (error) {
    log.info("error signing out: ", error);
  }
}

const AuthContext = createContext(undefined);

type AuthContextProps = {
  children?: ReactNode
};

export const AuthProvider = ({ children }: AuthContextProps) => {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    const hubListener = async (data) => {
      const { payload } = data;
      log.info("Auth event ", payload.event);
      if (payload.event === "signIn") {
        setUser(payload.data.attributes.name);
      } else if (payload.event === "signOut") {
        log.info("SIGNED OUT ");
        setUser(undefined);
      }
    };

    Auth.currentAuthenticatedUser()
      .then((user) => {
        log.info("found authenticated user (app): " + user);
        console.log(JSON.stringify(user));
        setUser(user.attributes.name);
      })
      .catch(function (error) {
        log.info("auth error:" + error);
      })
      .finally(function () { });
    const listener = Hub.listen("auth", hubListener);
    return () => {
      listener()
    };
  }, [user, setUser]);


  const getJwtToken = async () => {
    const auth = await Auth.currentSession();
    const token = auth.getAccessToken().getJwtToken();
    return token;
  }

  const isSignedIn = !!user
  const value: AuthContextValue = {
    user,
    isSignedIn,
    getJwtToken,
    signOut
  }
  return (<AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>);
};

export default AuthContext;
