import {
  Avatar,
  Button,
  Card,
  Col,
  ConfigProvider,
  Empty,
  Grid,
  Image,
  List,
  Popover,
  Row,
  Skeleton,
  Space,
  Statistic,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowLeftOutlined,
  BarChartOutlined,
  DotChartOutlined,
  EnvironmentOutlined,
  EyeOutlined,
  GoldOutlined,
  MailOutlined,
  SettingOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { ResponsiveBar } from "@nivo/bar";
import Page from "@screens/Page";
import {
  COLOR_BRAND_PRIMARY,
  COLOR_GRAY_LIGHT,
  COLOR_GRAY_PRIMARY,
  COLOR_DARK_PRIMARY,
} from "@constants/colors";
import { formatMillions, formatNumber, splitArray } from "src/utils";
import CalendarIcon from "@components/Icons/Calendar";
import PeopleIcon from "@components/Icons/PeopleIcon";
import EbitdaIcon from "@components/Icons/EbitdaIcon";
import RevenueIcon from "@components/Icons/RevenueIcon";
import { TabbedPeopleTable } from "@components/PeopleTable";
import FilterDropdown from "@components/FilterDropdown";
import { ReactComponent as Unavailable } from "@icons/Indicator_unavailable.svg";
import { ReactComponent as Verified } from "@icons/Indicator_verified.svg";
import RoleCard from "@components/RoleCard";
import { useNavigate, useParams } from "react-router";
import { CompanyReports } from "@screens/CompanyReports";
import useFundQuery from "@hooks/api/useFundQuery";
import useActiveFund from "@hooks/useActiveFund";
import useStaticConfig from "@hooks/useStaticConfig";
import { CurrencyDataType } from "src/types/currency";
import useConfigurationsQuery from "@hooks/api/useConfigurationsQuery";
import useReportsQuery from "@hooks/api/useReportsQuery";
import { Link } from "react-router-dom";
import log from "loglevel";



const { useBreakpoint } = Grid;

const { Text, Title } = Typography;

type StatsCardProps = {
  stats: {
    title: string;
    value: string | number;
    icon: ReactNode;
    formatter?: (value) => ReactNode;
  }[];
};

const StatsCard = ({ stats }: StatsCardProps) => {
  return (
    <Card style={{ marginBottom: "30px" }}>
      <Row>
        {stats.map((stat, index) => {
          return (
            <Col span={12} key={index}>
              <Statistic
                valueStyle={{
                  fontWeight: "700",
                  fontSize: "18px",
                  color: COLOR_DARK_PRIMARY,
                  lineHeight: "26px",
                }}
                key={index}
                title={stat.title}
                value={stat.value}
                formatter={stat.formatter}
                prefix={stat.icon}
                style={{ margin: "10px 0" }}
              />
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

type CompanyResult = {
  name: string;
  profileUrl: string;
  industry: string;
  totalEmployees: number;
  investmentDate: string;
  totalInvested: number;
  totalRevenue: number;
  totalEbitda: number;
  yearFounded: number;
  mission: string;
  description: string;
  website: string;
  address: string;
  verticalAlignments: string[];
  ownershipDemographics: string[];
  sdgAlignments: { title?: string; src: string }[];
  membersByRole: any;
  membersByTeam: any;
  performance: { categories: any[] };
  commitments: { goals: any[] };
  financialStatements: { title: string; url: string }[];
  bImpactStatements: { title: string; url: string }[];
  gRIStatements: { title: string; url: string }[];
};

const preparePortfolioCompany = (data): CompanyResult => {
  const { performance, commitments, ...rest } = data;
  return {
    ...rest,
    yearFounded: new Date(data.dateOfIncorporation).getFullYear(),
  };
};

const Detail = () => {
  const { activeFundId } = useActiveFund();
  const { id, section } = useParams();
  const [currency, setCurrency] = useState<CurrencyDataType>();
  const { data: configData, findCurrency } = useStaticConfig();
  const { data, isLoading } = useFundQuery(
    activeFundId,
    (data) => `/funds/${data.id}/portfolios/${id}`,
    {
      queryKey: ["funds", activeFundId, "portfolio", id],
      transform: preparePortfolioCompany,
    }
  );
  const [headlineStats, setHeadlineStats] = useState<
    {
      title: string;
      value: string | number;
      icon: ReactNode;
      formatter?: (value) => ReactNode;
    }[]
  >([]);

  useEffect(() => {
    if (data && configData) {
      const curr = findCurrency(data.currency);
      setCurrency(curr);
    }
  }, [data, configData, findCurrency]);

  useEffect(() => {
    if (data) {

      log.debug("Portfolio Detail:", data);

      setHeadlineStats([
        {
          title: "Year founded",
          value: data.yearFounded.toString(),
          icon: <CalendarIcon />,
          formatter: (value) => value,
        },
        {
          title: "Headcount",
          value: data.totalEmployees,
          icon: <PeopleIcon />,
          formatter: formatNumber,
        },
        {
          title: "Revenue",
          value: data.totalRevenue,
          icon: <RevenueIcon />,
          formatter: (value) => formatMillions(value, { currency }),
        },
        {
          title: "EBITDA",
          value: data.totalEbitda,
          icon: <EbitdaIcon />,
          formatter: (value) => formatMillions(value, { currency }),
        },
      ]);
    }
  }, [data, currency]);

  const navigate = useNavigate();

  return (
    <Page siderIndex="1">
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              colorPrimary: COLOR_BRAND_PRIMARY,
              colorPrimaryHover: COLOR_BRAND_PRIMARY,
            },
            Button: {
              colorPrimary: COLOR_BRAND_PRIMARY,
              colorPrimaryHover: COLOR_BRAND_PRIMARY,
            },
          },
          token: {
            fontSize: 12,
            borderRadius: 12,
          },
        }}
      >
        <Link to={`/portfolio/`} title="Back" style={{ marginRight: "10px" }}>
          <ArrowLeftOutlined />
        </Link>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "left",
            color: COLOR_DARK_PRIMARY,
            paddingRight: "10px",
            borderRightColor: COLOR_GRAY_LIGHT,
            borderRightStyle: "solid",
            borderRightWidth: "1px",
          }}
        >
          Portfolio
        </Text>
        <Text
          style={{
            paddingLeft: "10px",
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "left",
            color: COLOR_BRAND_PRIMARY,
          }}
        >
          {data?.displayName}
        </Text>
        <Tabs
          onTabClick={(key, e) => {
            navigate(`/portfolio/details/${id}/${key}`);
          }}
          defaultActiveKey={section || "overview"}
          items={[
            {
              Icon: DotChartOutlined,
              label: "Portfolio Overview",
              id: "overview",
            },
            { Icon: SettingOutlined, label: "Performance", id: "performance" },
            { Icon: EyeOutlined, label: "Commitments", id: "commitments" },
            { Icon: TeamOutlined, label: "People", id: "people" },
            { Icon: GoldOutlined, label: "Governance", id: "governance" },
            { Icon: BarChartOutlined, label: "Reports", id: "reports" },
          ].map((item, i) => {
            const key = item.id || item.label.toLowerCase();

            return {
              label: (
                <span>
                  <item.Icon></item.Icon>
                  {item.label}
                </span>
              ),
              key,
              children: isLoading ? (
                <Skeleton active={isLoading} avatar paragraph={{ rows: 4 }} />
              ) : (
                <>
                  {key === "overview" && (
                    <OverviewTab company={data} headlineStats={headlineStats} />
                  )}
                  {key === "performance" && (
                    <PerformanceTab data={data.performance} />
                  )}
                  {key === "commitments" && <CommitmentsTab company={data} />}
                  {key === "people" && <PeopleTab company={data} />}
                  {key === "governance" && <GovernanceTab company={data} />}
                  {key === "reports" && <ReportsTab company={data} />}
                </>
              ),
            };
          })}
        />
      </ConfigProvider>
    </Page>
  );
};

// Chart components
//
type PerformanceChartProps = {
  sections: { title: string; data: any[] }[];
  label?: string;
};
const GrowthChart = ({
  label,
  sections,
}: PerformanceChartProps): JSX.Element => {
  const [activeFacet, setActiveFacet] = useState<string>(
    Object.keys(sections[0].data)[0]
  );
  const [tabItems, setTabItems] = useState<
    { label: string; key: string; children: ReactNode }[]
  >([]);

  useEffect(() => {
    const tabs = sections.map((section, index) => {
      const { title: label } = section;
      const dataKeys = Object.keys(section.data);
      const data = dataKeys.reduce((acc, key) => {
        return {
          ...acc,
          [key]: section.data[key].map(({ key, items }: any) => ({
            key,
            ...items,
          })),
        };
      }, {});

      const dataSet = data[activeFacet];

      const keys = Object.keys(dataSet[0]).slice(1);
      return {
        label,
        key: String(index),
        children: (
          <div style={{ height: "500px", width: "100%" }}>
            <ResponsiveBar
              role="application"
              data={dataSet}
              keys={keys}
              valueFormat={(value) => `$${formatNumber(value)}`}
              innerPadding={30}
              axisTop={null}
              axisRight={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: "middle",
                legendOffset: -40,
              }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "year",
                legendPosition: "middle",
                legendOffset: 32,
              }}
              groupMode="grouped"
              indexBy="key"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-left",
                  direction: "row",
                  itemWidth: 120,
                  itemHeight: 0,
                  itemOpacity: 0.85,
                  symbolSize: 16,
                  translateY: 40,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        ),
      };
    });
    setTabItems(tabs);
  }, [activeFacet, sections]);

  const moreElements = Object.keys(sections[0].data).map((button, index) => {
    return (
      <Button
        type={activeFacet === button ? "primary" : "default"}
        style={{
          borderRadius: "5px",
          fontSize: 12,
          fontWeight: 500,
          margin: "8px 4px",
        }}
        size="middle"
        key={index}
        onClick={() => {
          setActiveFacet(button);
        }}
      >
        {button}
      </Button>
    );
  });

  return (
    <Row>
      <Col span={24}>
        {label && (
          <Text style={{ paddingBottom: "20px", fontSize: "16px" }}>
            {label}
          </Text>
        )}
        <Tabs
          tabBarExtraContent={{ right: <div>{moreElements}</div> }}
          style={{ height: "500px" }}
          type="card"
          defaultActiveKey={"0"}
          items={tabItems}
        />
      </Col>
    </Row>
  );
};

const WorkforceChart = ({ sections }: PerformanceChartProps): JSX.Element => {
  return (
    <div>
      <Empty description="No data available"></Empty>
    </div>
  );
  // return (<div style={{ height: '500px', width: '100%' }} ><ResponsiveBar
  //   role="application"
  //   data={sections}
  //   keys={[
  //     'sales',
  //     'ebitda',
  //     'netIncome'
  //   ]}
  //   valueFormat={value => `$${formatNumber(value)}`}
  //   innerPadding={30}
  //   axisTop={null}
  //   axisRight={null}
  //   axisLeft={{
  //     tickSize: 5,
  //     tickPadding: 5,
  //     tickRotation: 0,
  //     legendPosition: 'middle',
  //     legendOffset: -40
  //   }}
  //   axisBottom={{
  //     tickSize: 5,
  //     tickPadding: 5,
  //     tickRotation: 0,
  //     legend: 'year',
  //     legendPosition: 'middle',
  //     legendOffset: 32
  //   }}
  //   groupMode="grouped"
  //   indexBy="year"
  //   margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
  //   padding={0.3}
  //   valueScale={{ type: 'linear' }}
  //   indexScale={{ type: 'band', round: true }}
  //   legends={[
  //     {
  //       dataFrom: 'keys',
  //       anchor: 'bottom-left',
  //       direction: 'row',
  //       itemWidth: 120,
  //       itemHeight: 0,
  //       itemOpacity: 0.85,
  //       symbolSize: 16,
  //       translateY: 40,
  //       effects: [
  //         {
  //           on: 'hover',
  //           style: {
  //             itemOpacity: 1
  //           }
  //         }
  //       ]
  //     }
  //   ]}
  // /></div>)
};

const getGraphComponent = (type: string) => {
  switch (type) {
    case "growth":
      return GrowthChart;
    case "workforce":
      return WorkforceChart;
    case "environment":
      return WorkforceChart;
    case "governance":
      return WorkforceChart;
    default:
      return null;
  }
};

const PerformanceTab = ({ data }) => {
  //  TODO: add filter dropdown
  const [tabItems, setTabItems] = useState([]);

  useEffect(() => {
    if (data && data.categories) {
      const tabs = data.categories.map((category, index) => {
        const { title: label, sections } = category;
        const key: string = label.toLowerCase();
        const Graph = getGraphComponent(key);
        return {
          label,
          key,
          children: <>{Graph && <Graph sections={sections} />}</>,
        };
      });
      setTabItems(tabs);
    }
  }, [data]);
  const showFilter = false;

  return (
    <Row>
      <Col span={24}>
        <Text style={{ paddingBottom: "20px", fontSize: "16px" }}>
          Performance
        </Text>
        {tabItems && tabItems.length === 0 && (
          <Empty description="Performance data is unavailable"></Empty>
        )}
        {tabItems && tabItems.length > 0 && (
          <Tabs
            tabBarExtraContent={
              showFilter ? (
                <FilterDropdown
                  label="Filter by year"
                  options={[{ label: "2020", value: "2020" }]}
                />
              ) : null
            }
            style={{ height: "500px" }}
            defaultActiveKey="1"
            items={tabItems}
          />
        )}
      </Col>
    </Row>
  );
};

const CommitmentsTab = ({ company }) => (
  <Row>
    <Col span={24}>
      <Text style={{ paddingBottom: "20px", fontSize: "16px" }}>
        Strategic Goals
      </Text>
      <Card
        headStyle={{
          fontSize: 12,
          fontWeight: 500,
          borderBottom: 0,
          marginBottom: 0,
        }}
        extra={
          <FilterDropdown
            label="Filter by date"
            isLoading={false}
            options={[
              {
                value: "2010",
                label: "2010",
              },
              {
                value: "2011",
                label: "2011",
              },
              {
                value: "2012",
                label: "2012",
              },
              {
                value: "2013",
                label: "2013",
              },
              {
                value: "2014",
                label: "2014",
              },
              {
                value: "2015",
                label: "2015",
              },
            ]}
          />
        }
      >
        <Tabs
          style={{ height: "500px" }}
          type="card"
          defaultActiveKey="1"
          items={[
            { label: "Eliminating Products that use Virgin Materials" },
            { label: "Reduce Carbon Footprint in Supply Chain" },
            { label: "Maintaining 1% Giving" },
            { label: "Drive DE&I within the Organization" },
          ].map(({ label }, i) => {
            return {
              label,
              key: label.toLowerCase(),
              children: (
                <>{i === 0 && <Empty description={"Data unavailable"} />}</>
              ),
            };
          })}
        />
      </Card>
    </Col>
  </Row>
);

const RoleCardRow = ({ roles, index }) => {
  const [role1, role2] = roles;
  //log.debug("Role1:", role1);
  //log.debug("Role2:", role2);

  const { sm } = useBreakpoint();
  const children = (
    <>
      <Col
        span={24}
        sm={{ span: 12 }}
        style={{ paddingRight: sm ? "20px" : 0 }}
      >
        <RoleCard
          role={role1}
          icon={
            role1.members && role1.members.length > 0 ? (
              <Verified />
            ) : (
              <Unavailable />
            )
          }
        />
      </Col>
      <Col span={24} sm={{ span: 12 }} style={{ marginTop: sm ? 0 : "20px" }}>
        {
          (role2) ?
          <RoleCard
          role={role2}
          icon={
            role2.members && role2.members.length > 0 ? (
              <Verified />
            ) : (
              <Unavailable />
            )
          }
        />
        :
        <></>
        }
      </Col>
    </>
  );

  return <Row style={{ marginBottom: "20px" }}>{children}</Row>;
};

const GovernanceTab = ({ company }) => {
  const [items, setItems] = useState([]);
  const [roles, setRoles] = useState();
  const { t } = useTranslation();

  const { data: configData, isLoading } = useConfigurationsQuery();

  //log.debug("Roles:", roles);
  useEffect(() => {
    if (company?.governance) {
      const shaped = company.governance.map((item) => {
        return {
          key: item.id,
          title: item.displayName,
          icon: item.value ? <Verified /> : <Unavailable />,
        };
      });
      setItems(shaped);
    }
  }, [company?.governance]);

  useEffect(() => {
    if (company?.membersByRoles && configData?.staticRoles) {
      const shaped = configData.staticRoles.map((role: any) => {
        return {
          key: role.id,
          title: t(
            `portfolio.internal_oversight_roles.${role.name.toLowerCase()}`,
            {
              defaultValue: role.displayName,
            }
          ),

          members: (company.membersByRoles[role.name] || []).map((member) => {
            return {
              key: member.id,
              name: member.name,
              src: member.profileUrl,
            };
          }),
        };
      });
      setRoles(shaped);
    }
  }, [company?.membersByRoles, configData, t]);

  return (
    <Row>
      <Col span={24}>
        <Text style={{ paddingBottom: "20px", fontSize: "16px" }}>
          Governance
        </Text>
        <Row style={{ marginTop: "20px" }}>
          <Col
            span={24}
            md={{ span: 12 }}
            style={{ paddingRight: "20px", marginBottom: "20px" }}
          >
            <Card
              title={<Text className="text-xs font-semibold">Corporate Policies and Procedures</Text>}
              headStyle={{ borderBottom: 0 }}
            >              
              <List
                grid={{ gutter: 16, column: 2 }}
                dataSource={items}
                renderItem={(item) => (
                  

                  <List.Item>
                    <List.Item.Meta
                      avatar={item.icon}
                      description={<Text className="text-xs font-medium">{item.title}</Text>}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <Card
              title={<Text className="text-xs font-semibold">Internal Oversight Roles</Text>}
              headStyle={{ borderBottom: 0 }}
            >
              {isLoading || !roles ? (
                <Skeleton avatar active paragraph={{ rows: 4 }} />
              ) : (
                splitArray(roles, 2).map((roleRows, index) => {
                  return <RoleCardRow key={index} roles={roleRows} index={index} />;
                })
              )}
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const ReportsTab = ({ company: currentCompany }) => {
  const { id } = currentCompany || {};
  const { activeFundId } = useActiveFund();
  const { data: companyReports, isLoading } = useReportsQuery(
    activeFundId,
    true
  );
  const [reports, setReports] = useState();

  useEffect(() => {
    if (companyReports) {
      const reports = companyReports.find(({ company }) => company.id === id);
      setReports(reports);
    }
  }, [companyReports, id]);

  if (isLoading) {
    return <Skeleton />;
  }
  if (!reports) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="There aren't any company level reports"
      />
    );
  }

  return (
    <Row>
      <Col span={24}>
        <Title level={5}>Reports</Title>
        {!isLoading ? (
          <CompanyReports company={reports} index={0} />
        ) : (
          <Skeleton />
        )}
      </Col>
    </Row>
  );
};

const OverviewTab = ({ company, headlineStats }) => (
  <Card>
    <Row>
      <Col sm={{ span: 24 }} lg={{ span: 12 }}>
        <Row>
          <Col span={3} className="min-w-[72px]">
            <Avatar size={64} shape="square" src={company.profileUrl} />
          </Col>

          <Col span={18}>
            <Text style={{ fontSize: "22px", fontWeight: "500" }}>
              {company?.displayName}
            </Text>
            <p>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color: COLOR_GRAY_PRIMARY,
                }}
              >
                {company?.vision}
              </Text>
            </p>
            <div>
              <EnvironmentOutlined style={{ marginRight: "10px" }} />
              <Text style={{ fontSize: 12, fontWeight: 500 }}>
                {company.address}
              </Text>
            </div>
            <div>
              <MailOutlined style={{ marginRight: "10px" }} />
              <Text style={{ fontSize: 12, fontWeight: 500 }}>
                {company.email}
              </Text>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="mt-4">
            <Title level={5}>Mission Statement</Title>
            <Text style={{ fontWeight: 300, fontSize: "12px" }}>
              {company?.mission}
            </Text>
          </Col>
        </Row>

        <Row style={{ paddingTop: "20px" }}>
          <Col span={8}>
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: COLOR_GRAY_PRIMARY,
              }}
            >
              Industry
            </Text>
            <div>
              <Space size={[0, 8]} wrap>
                <Tag
                  style={{
                    padding: "6px",
                    fontWeight: 500,
                    marginTop: "8px",
                    borderRadius: "20px",
                    fontSize: "12px",
                  }}
                  color="red"
                >
                  {company.industry}
                </Tag>
              </Space>
            </div>
          </Col>
          <Col span={16}>
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: COLOR_GRAY_PRIMARY,
              }}
            >
              Verticals
            </Text>
            <div>
              <Space size={[0, 8]} wrap>
                {company.verticalAlignments.map((item, i) => {
                  return (
                    <Tag
                      key={i.id}
                      style={{
                        padding: "6px",
                        fontWeight: 500,
                        marginTop: "8px",
                        borderRadius: "20px",
                        fontSize: "12px",
                      }}
                      color="blue"
                    >
                      {item.name}
                    </Tag>
                  );
                })}
              </Space>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "20px" }}>
          <Col span={24}>
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: COLOR_GRAY_PRIMARY,
              }}
            >
              Ownership
            </Text>
            <div>
              <Space size={[0, 8]} wrap>
                {Array(company.ownershipDemographics)
                  .flat()
                  .map((item, i) => {
                    return (
                      <Tag
                        style={{
                          padding: "6px",
                          fontWeight: 500,
                          marginTop: "8px",
                          borderRadius: "20px",
                          fontSize: "12px",
                        }}
                        color="purple"
                      >
                        {item}
                      </Tag>
                    );
                  })}
              </Space>
            </div>
          </Col>
        </Row>
      </Col>
      <Col sm={{ span: 24 }} lg={{ span: 12 }}>
        {headlineStats && headlineStats.length > 0 ? (
          <StatsCard stats={headlineStats} />
        ) : (
          <Skeleton active avatar paragraph={{ rows: 4 }} />
        )}
        <SDGCard sdgs={company.sdgAlignments} />
      </Col>
    </Row>
  </Card>
);

type SDGCardProps = {
  sdgs: any[];
};

const SDGCard = ({ sdgs }: SDGCardProps) => {
  return (
    <Card headStyle={{ border: 0 }} title="SDG Alignment">
      {sdgs &&
        sdgs.map((sdg, i) => {
          return (
            <Space style={{ marginRight: "12px" }}>
              <Popover content={sdg.number} title={sdg.name}>
                <Image
                  preview={false}
                  key={i}
                  src={sdg.url}
                  width={42}
                  height={42}
                />
              </Popover>
            </Space>
          );
        })}
    </Card>
  );
};
const PeopleTab = ({ company }) => {
  const { membersByTeam } = company;
  const { t } = useTranslation();
  const [tabData, setTabData] = useState<any[]>([]);

  useEffect(() => {
    const data = Object.keys(membersByTeam).map((key) => {
      return {
        label: t(`portfolio.people.tabs.${key.toLowerCase()}`),
        tableProps: { list: membersByTeam[key] },
      };
    });
    setTabData(data);
  }, [membersByTeam]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row>
      <Col span={24}>
        <Text style={{ paddingBottom: "20px", fontSize: "16px" }}>People</Text>
        <Card style={{ marginTop: "20px" }}>
          {tabData && tabData.length > 0 ? (
            <TabbedPeopleTable tabs={tabData} />
          ) : (
            <Empty description="People data is unavailable" />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Detail;
