import {GoalsTable} from "@components/Goals/GoalsTable";
import { Typography } from "antd";

const { Text } = Typography;

type Props = {
  goal: Map<string, any>;
};

export const FundGoal = ({ goal }: Props) => {

  return (
    <div className='mb-16' key={goal['id']}>
      <div className='py-4 pr-48'>
        <Text style={{color:'#4B5563'}}>{goal['thesis'].name}</Text>
      </div>

      <GoalsTable goals={goal['goalMetrics']}/>
    </div>
    
  );
}
